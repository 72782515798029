// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/splines-right.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../assets/splines-left.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#landing-page[data-v-37a41e1e]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + "),url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");background-position:100% 100%,0 100%;background-repeat:no-repeat;background-size:33%}@media only screen and (min-width:960px) and (max-width:1263px){#landing-page[data-v-37a41e1e]{background-size:50%!important}}@media only screen and (max-width:959px){#landing-page[data-v-37a41e1e]{background-size:70%!important}}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
