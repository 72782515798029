// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/vid-poster.jpeg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ssr-poster[data-v-92cb4072]{width:100vw;overflow:hidden;background-size:cover;background-position:50%;height:100vh;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.vue-responsive-videobg[data-v-92cb4072]{background:none;position:relative;width:100%;overflow:hidden}.video-overlay[data-v-92cb4072],.vue-responsive-videobg .videobg-content[data-v-92cb4072]{position:absolute;top:0;left:0;width:100%;height:100%}.video-overlay[data-v-92cb4072]{background:linear-gradient(180deg,transparent,#000 73.96%)}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
