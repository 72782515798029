//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Hero from '@/components/Hero'
import FeaturedProject from '@/components/FeaturedProject'
import CuratedProjects from '@/components/CuratedProjects'
import FeaturedCreators from '@/components/FeaturedCreators'
import LandingFooter from '@/components/LandingFooter'

export default {
  name: 'HomePage',
  components: {
    Hero,
    FeaturedProject,
    CuratedProjects,
    FeaturedCreators,
    LandingFooter,
  },
  data() {
    return {
      featuredProject: {},
      featuredArtist: {},
      featuredArtists: [],
      curatedProjects: [],
      sliderTokens: [],
    }
  },
  async fetch() {
    await Promise.all([
      this.loadFeaturedProject(),
      this.loadCuratedProjects(),
      this.loadFeaturedArtists(),
    ])
  },
  methods: {
    async loadFeaturedProject() {
      try {
        const resp = await this.$axios.$get(
          `/api/projects?type=featured&limit=1`
        )
        this.featuredProject =
          resp?.projects?.length > 0 ? resp.projects[0] : {}
        const respTokens = await this.$store.dispatch(
          'projects/loadProjectFeaturedTokens',
          this.featuredProject.id
        )
        let tokens = respTokens?.data
        if (tokens && tokens.length > 4) {
          tokens = tokens.slice(0, 4)
        }

        const respFeatToken = await this.$store.dispatch(
          'projects/loadProjectFeaturedToken',
          this.featuredProject.id
        )

        this.sliderTokens.length = 0
        if (respFeatToken.data.length) {
          this.sliderTokens.push(respFeatToken.data[0])
        }
        if (tokens) {
          for (let i = 0; i < tokens.length; i++) {
            this.sliderTokens.push(tokens[i])
          }
        }
        // console.log(respFeatToken, tokens, this.sliderTokens)
        this.featuredArtist = await this.$axios.$get(
          `/api/users/artist/${this.featuredProject.user_id}`
        )
      } catch (err) {
        console.error(err)
      }
    },
    async loadCuratedProjects() {
      try {
        const resp = await this.$axios.$get(
          `/api/projects?type=curated&limit=9`
        )
        this.curatedProjects = resp?.projects?.length > 0 ? resp.projects : []
      } catch (err) {
        console.error(err)
      }
    },
    async loadFeaturedArtists() {
      try {
        const respCreators = await this.$axios.$get(
          '/api/users/artists?is_featured=true'
        )
        this.featuredArtists = respCreators.users
      } catch (err) {
        console.error(err)
      }
    },
  },
}
