//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FeaturedCreatorCard from '@/components/FeaturedCreatorCard'

  export default {
    name: 'CuratedProjects',

    components: {
      FeaturedCreatorCard
    },
    props: {
      featuredCreators: {
        type: Array,
        default: null
      }
    },
    data: () => ({
      category: 'All',
      categories: [
        'All',
        'Wordpress',
        'Branding',
        'Statistics',
        'Shopping',
        'Photography',
        'Ecommerce',
      ],
    }),
    methods: {
      filter (card) {
        return card.category === this.category || this.category === 'All'
      },
      onIntersect(entries, observer) {
        entries.forEach(entry => {
          if(entry.isIntersecting) {
            entry.target.classList.add('fade-in')
          }
        })
      }
    },
  }
