//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VideoBackgroundSSR from './VideoBackgroundSSR.vue'

export default {
  name: 'SectionHero',
  components: {
    VideoBackgroundSSR,
  },
  provide: {
    theme: { isDark: true },
  },
}
