//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment'
import { sortBy } from 'lodash'
import CuratedProjectCard from '@/components/CuratedProjectCard'

export default {
  name: 'CuratedProjects',

  components: {
    CuratedProjectCard
  },
  props: {
    curatedProjects: {
      type: Array,
      default: null,
    },
    reload: {
      type: Function,
      default: () => {}
    }
  },
  data: () => ({
    category: 'All',
    categories: [
      'All',
      'Wordpress',
      'Branding',
      'Statistics',
      'Shopping',
      'Photography',
      'Ecommerce',
    ],
    featuredProjects: [],
    currentDate: null,
    isLoading: true,
  }),
  computed: {
    projects() {
      return sortBy(this.curatedProjects, ['start_at'])
    },
  },
  mounted() {
    this.currentDate = moment()
  },
  methods: {
    formatDate(date) {
      return moment(date)
    },
    footerBackground(status) {
      const { statuses } = this.$config
      return {
        'nfty-feature-project-footer': status === statuses.scheduled,
        black: status === statuses.ongoing,
        white: status === statuses.ended || status === statuses.sold,
        'black--text': status === statuses.ended || status === statuses.sold,
      }
    },
    projectStatus(project) {
      const { statuses } = this.$config
      if (project.status === statuses.scheduled) {
        return 'Starting in'
      } else if (
        project.status === statuses.ongoing &&
        project.end_at !== null
      ) {
        return 'Ending'
      } else if (project.status === statuses.ongoing) {
        return 'Ongoing'
      } else {
        return 'Now Trading'
      }
    },
    showCountdown(project) {
      const { statuses } = this.$config
      if (
        project.status === statuses.scheduled ||
        (project.status === statuses.ongoing && project.end_at !== null)
      ) {
        return true
      }
      return false
    },
    filter(card) {
      return card.category === this.category || this.category === 'All'
    },
    onIntersect(entries, observer) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('fade-in')
        }
      })
    },
  },
}
