//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'FeaturedArtistCard',
  components: {

  },
  props: {
    title: {
      type: String,
      default: ''
    },
    artist: {
      type: String,
      default: ''
    },
    hero: {
      type: String,
      default: ''
    },
    src: {
      type: String,
      default: ''
    },
    lazySrc: {
      type: String,
      default: ''
    },
    desc: {
      type: String,
      default: ''
    },
    socialLinks: {
      type: Object,
      default: null
    },
    avatar: {
      type: String,
      default: ''
    },
  },
  data: () => ({
    category: 'All',
    categories: [
      'All',
      'Wordpress',
      'Branding',
      'Statistics',
      'Shopping',
      'Photography',
      'Ecommerce',
    ],
  }),
  methods: {
    filter (card) {
      return card.category === this.category || this.category === 'All'
    },
    onIntersect(entries, observer) {
      entries.forEach(entry => {
        if(entry.isIntersecting) {
          entry.target.classList.add('fade-in')
        }
      })
    }
  },
}
