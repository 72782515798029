//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import LoginSignup from '@/components/LoginSignup'

export default {
  name: 'SectionHero',
  components: {
    // LoginSignup
  },
  provide: {
    theme: { isDark: true },
  },
  props: {
    slogan: {
      type: String,
      default: 'We’re empowering creatives and championing beautiful art'
    }
  },
  asyncData() {
    return {
      tproj: null
    }
  },
  computed: {
     minHeight () {
      const height = this.$vuetify.breakpoint.mdAndUp ? '100vh' : '50vh'
      return `calc(${height} - ${this.$vuetify.application.top}px)`
    },
  },
  mounted() {
  },
  methods: {
    isAuthenticated() {
      return this.$store.getters.isAuthenticated  // it check if user isAuthenticated
    },
    getUserInfo() {
      return this.$store.getters.getUserInfo
    },
    login() {
      const user = this.$store.getters.getUserInfo
      user ? this.$router.push('/crowdsale') : this.$refs.loginSignup.openDialog()
    },
  }
}
